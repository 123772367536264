<template>
	<div>
		<!-- 
			Backend Errors 
		-->
		<div v-if="isError === true">
			<div v-for="error in errors" :key="error">
				<div v-for="el in error" :key="el">
					<ion-item>
						<div tabindex="0"></div><!-- bug fix for safari 14 -->
						<ion-text color="danger">{{el}}</ion-text>
					</ion-item>
				</div>
			</div>
		</div>
		<!-- 
			Name 
		-->
		<ion-item>
			<div tabindex="0"></div><!-- bug fix for safari 14 -->
			<ion-input v-model="name" type="text" placeholder="Name"></ion-input>
		</ion-item>		
		<!-- 
			Status 
		-->
		<ion-item>
			<div tabindex="0"></div><!-- bug fix for safari 14 -->
			<ion-label position="stacked">Status</ion-label>
			<ion-select placeholder="Select One" v-model="status">
				<ion-select-option :value="1">Published</ion-select-option>
				<ion-select-option :value="0">Unpublished</ion-select-option>
				<ion-select-option v-if="id > 0" :value="2">Delete</ion-select-option>
			</ion-select>
		</ion-item>
		<!-- 
			Actions 
		-->
		<ion-item lines="none">
			<div tabindex="0"></div><!-- bug fix for safari 14 -->
			<ion-button v-if="isValidated === true" slot="end" expand="full" size="small" color="primary" @click.prevent="saveGenre()">
				Save
			</ion-button>
		</ion-item>
	</div>
</template>

<script>
import { IonItem, IonInput, IonLabel, IonSelect, IonSelectOption, IonButton, IonText, loadingController, toastController } from '@ionic/vue';
import { defineComponent, computed, ref, onMounted } from 'vue';
import { useStore } from "vuex";
import apiClient from '../services/api';
import { useRouter } from 'vue-router';

export default defineComponent({
	name: 'GenreEditForm',
	props: ['id'],
	components: {
		IonItem, IonInput, IonLabel, IonSelect, IonSelectOption, IonButton, IonText
	},
	setup(props) {
		const router = useRouter();
		const store = useStore();
		const authUser = computed(() => store.state.authUser);
		const name = ref(null);
		const genre = ref([]);
		const status = ref(1);
		const isValidated = ref(false);
		const isError = ref(false);
		const errors = ref([]);
		const toastMessage = ref(null);
		const timeout = { default: 6000 }

		onMounted(() => {
			if(props.id > 0) {
				getGenre()
			}
		})

		function getGenre() {
			apiClient.get('/api/get/genre/id/'+props.id,
			{
				headers: {
					'Authorization':  `Bearer ${store.state.authToken}`
				}
			}).then(response => {
				genre.value = response.data
				if(genre.value) {
					name.value = genre.value.name
					status.value = genre.value.status
				}
			}).catch(error => {
				console.log(error)
			}); 			
		}

		function saveGenre() {
			var apiUrl = '/api/post/genre'
			if(props.id > 0) {
				apiUrl = '/api/update/genre/'+props.id
			}
			presentLoading()
			apiClient.post(apiUrl, 
			{
				name: name.value,  
				status: status.value,
			},
			{
				headers: {
					'Authorization':  `Bearer ${store.state.authToken}`
				}
			})
			.then(response => {
				// Success
				if(response.data.message == 'success') {
					toastMessage.value = 'You have saved a genre.'
					openToast()
					isError.value = false
					errors.value = []
					name.value = null
					status.value = 1
					if(props.id > 0) {
						goBack()
					}
				}
				// Success
				if(response.data.message == 'success-delete') {
					toastMessage.value = 'You have deleted a genre.'
					openToast()
					isError.value = false
					errors.value = []
					goBack()
				}
				// Fail 
				if(response.data.message == 'fail') {
					toastMessage.value = 'There was a problem.'
					openToast()
					isError.value = true
					errors.value = response.data.errors            
				}
				// Fail Validation
				if(response.data.message == 'fail-validation') {
					toastMessage.value = 'There was a problem.'
					openToast()
					isError.value = true
					errors.value = response.data.errors            
				}
				// Fail Auth
				if(response.data.message == 'fail-auth') {
					toastMessage.value = 'You are not authorized to do this.'
					openToast()
				}
				// Fail Auth
				if(response.data.message == 'fail-slug') {
					toastMessage.value = 'That hashtag is already taken.'
					openToast()
				}
			})
			.catch(error => {
				// credentials didn't match
				isError.value = true
				errors.value = error.response.data.errors
			});        	
		}

		async function presentLoading() {
			const loading = await loadingController
			.create({
				cssClass: 'my-custom-class',
				message: 'Please wait...',
				duration: timeout,
			});

			await loading.present();

			setTimeout(function() {
				loading.dismiss()
			}, timeout);
		}

		async function openToast() {
			const toast = await toastController
			.create({
				//message: '',
				duration: 6000,
				message: toastMessage.value,
				position: 'bottom',
				buttons: [
				{
					icon: 'close',
					role: 'cancel',
					handler: () => {

					}
				}]
			})
			return toast.present();
		}

		function goBack() {
			router.push('/admin-genres')
		}

		return {
			authUser, genre, getGenre, name, status, isValidated, isError, errors, saveGenre, router
		}
	},
	watch: {
		id: function() {
			if(this.id > 0) {
				this.getGenre()
			}
		},
		name: function() {
			if(this.name != null && this.name != '') {
				this.isValidated = true
			}
		}	
	}
});
</script>